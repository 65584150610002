import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueYoutube from 'vue-youtube'
import VueI18n from 'vue-i18n'
import forceI18n from './plugins/force-i18n'
import VueGtm from '@gtm-support/vue2-gtm'
import VueRouter from 'vue-router'
import VueCookieBot from '@ambitiondev/vue-cookiebot-plugin'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(VueCookieBot, {
  cookieBotID: 'ff6039d5-dde7-4be5-9cb3-2a01a11be3d3'
})
Vue.use(VueRouter)
Vue.use(VueYoutube)
Vue.use(VueI18n)
Vue.use(VueGtm, {
  id: 'GTM-5RS8GBX',
  defer: false,
  compatibility: false,
  enabled: true,
  debug: true,
  loadScript: true,
  trackOnNextTick: false
})

Vue.directive('force-i18n', forceI18n)

const i18n = new VueI18n({
  locale: (navigator.language || navigator.userLanguage).split('-')[0],
  fallbackLocale: ['it']
})

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: () => import(/* webpackChunkName: "public" */ './components/Home.vue') },
    { path: '/privacy-policy', component: () => import(/* webpackChunkName: "public" */ './components/PrivacyPolicy.vue') },
    { path: '*', name: 'NotFound', component: () => import(/* webpackChunkName: "public" */ './components/NotFound.vue') }
  ]
})

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
