<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted () {
    this.$cookiebot.consentBanner()

    const faviconEl = document.querySelector('link[rel=icon]')
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) faviconEl.setAttribute('href', '/favicon_stg_negative.png')
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/global.scss';
</style>
