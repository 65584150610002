import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    scrollPosition: 0,
    scrollDirection: null,
    scroller: null,
    appReady: false,
    introVideoHasPlayed: false,
    images: {
      introduction: [],
      ring_rotation: []
    }
  },
  getters: {
    scrollPosition: (state) => state.scrollPosition,
    scroller: (state) => state.scroller,
    scrollDirection: (state) => state.scrollDirection,
    appReady: (state) => state.appReady,
    introVideoHasPlayed: (state) => state.introVideoHasPlayed,
    images: (state) => state.images
  },
  mutations: {
    setScroller (state, scroller) {
      state.scroller = scroller
    },
    setScrollPosition (state, position) {
      state.scrollPosition = position
    },
    setScrollDirection (state, direction) {
      state.scrollDirection = direction
    },
    setAppReady (state, isReady) {
      state.appReady = isReady
    },
    setIntroVideoHasPlayed (state, hasPlayed) {
      state.introVideoHasPlayed = hasPlayed
    },
    pushImage (state, { section, index, image }) {
      state.images[section][index] = image
    }
  }
  // actions: {
  // },
  // modules: {
  // }
})
