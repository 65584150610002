import Vue from 'vue'

export default {
  update (el, bind) {
    if (bind.value.locale === bind.oldValue.locale || !bind.value.message) return
    Vue.nextTick(() => {
      el.innerHTML = bind.value.message
    })
  }
}
